import { Shed } from '@/models/Shed';
import {
  getBoxDevices,
  getDevices,
  getShedBatches,
  getShedBatchesNutrition,
  getShedDetails,
  getShedDetailsOld,
  getSheds,
} from '@/services/environments';
import { isNotNull } from '@/utils';
import { getPcpTargetValue } from '@/utils/pcp';

export const actions = {
  async loadSheds({ rootGetters, commit }, { params = [], state, page, itemsPerPage }) {
    const token = rootGetters['user/getToken'];
    let sheds = new Array();
    let response = await getSheds(
      token.access,
      itemsPerPage,
      (page - 1) * itemsPerPage,
      params,
      state
    );

    if (response.results) {
      response.results.forEach((element) => {
        let shed = new Shed(element);
        sheds.push(shed);
      });
    }

    if (state == 'pcp') {
      let pcpSheds = []
      sheds.forEach((element) => {
        pcpSheds.push({
          'core': element.core,
          'name': element.name,
          'id': element.id,
          'age': element.lastBatch.age,
          'entry_weight': element.lastBatch?.entry_weight,
          'weight': element.lastMetrics.weight ?? '--',
          'performance': element.lastMetrics.performance ?? '--',
          'pp1': {
            'data': element.lastBatch.pcp_projections[7]?.weight || (element.lastBatch.pcp_projections[7]?.projection ?? '--'),
            'isWeight': !!element.lastBatch.pcp_projections[7]?.weight
          },
          'pp2': {
            'data': element.lastBatch.pcp_projections[14]?.weight || (element.lastBatch.pcp_projections[14]?.projection ?? '--'),
            'isWeight': !!element.lastBatch.pcp_projections[14]?.weight
          },
          'pp3': {
            'data': element.lastBatch.pcp_projections[21]?.weight || (element.lastBatch.pcp_projections[21]?.projection ?? '--'),
            'isWeight': !!element.lastBatch.pcp_projections[21]?.weight
          },
          'pp4': {
            'data': element.lastBatch.pcp_projections[28]?.weight || (element.lastBatch.pcp_projections[28]?.projection ?? '--'),
            'isWeight': !!element.lastBatch.pcp_projections[28]?.weight
          },
          'pp5': {
            'data': element.lastBatch.pcp_projections[35]?.weight || (element.lastBatch.pcp_projections[35]?.projection ?? '--'),
            'isWeight': !!element.lastBatch.pcp_projections[35]?.weight
          },
          'pp6': {
            'data': element.lastBatch.pcp_projections[42]?.weight || (element.lastBatch.pcp_projections[42]?.projection ?? '--'),
            'isWeight': !!element.lastBatch.pcp_projections[42]?.weight
          },
          'pp7': {
            'data': element.lastBatch.pcp_projections[49]?.weight || (element.lastBatch.pcp_projections[49]?.projection ?? '--'),
            'isWeight': !!element.lastBatch.pcp_projections[49]?.weight
          },
          'target_weight': getPcpTargetValue(element.lastBatch, element.company) || '--'
        });
      })
      commit('SET_PCP_SHEDS', pcpSheds);
    } else {
      commit('SET_SHEDS', sheds);
    }
    commit('SET_SHEDS_ENTHALPY_COUNT', response.enthalpy_count);
    commit('SET_TOTAL_SHEDS', response.count);
    commit('SET_SHEDS_IS_LOADED', true);
  },

  async loadShedDetailsOld({ rootGetters, commit, dispatch }, id) {
    const token = rootGetters['user/getToken'];
    const currentShed = rootGetters['environment/getSelectedShed'];
    const sheds = rootGetters['environment/getSheds'];

    if (currentShed && currentShed.id === id) return;

    dispatch('environmentFilter/resolveParams', null, { root: true });

    let response = await getShedDetailsOld(token.access, id);
    let detailedShed = new Shed(response);


    let shed = sheds.find((shed) => shed.id === detailedShed.id);
    if (isNotNull(shed)) {
      Object.assign(detailedShed.lastBatch, {
        weight_projection: shed.lastBatch.weight_projection,
      });
    }

    let batches = await getShedBatches(token.access, id);
    Object.assign(detailedShed, { batches: batches.results });

    commit('SET_SELECTED_SHED', detailedShed);
    commit('SET_LAST_BATCH', detailedShed.lastBatch);
  },

  async loadShedDetails({ rootGetters, commit, dispatch }, { id, params = {} }) {
    const token = rootGetters['user/getToken'];
    const currentShed = rootGetters['environment/getSelectedShed'];
    const sheds = rootGetters['environment/getSheds'];

    const { old_batch_id = undefined, new_batch_id = undefined } = params


    if (new_batch_id && old_batch_id === new_batch_id && currentShed?.id === id) return;

    // id = id || params.id || currentShed?.id

    dispatch('environmentFilter/resolveParams', null, { root: true });

    let response = await getShedDetails(token.access, id, {
      batch_id: currentShed?.lastBatch?.id,
    });
    let detailedShed = new Shed(response);


    let shed = sheds.find((shed) => shed?.id === detailedShed?.id);
    if (isNotNull(shed)) {
      Object.assign(detailedShed.lastBatch, {
        weight_projection: shed.lastBatch.weight_projection,
      });
    }

    // let batches = await getShedBatches(token.access, id);
    let batchesNutrition = await getShedBatchesNutrition(token.access, id);
    // Object.assign(detailedShed, { batches: batches.results });
    Object.assign(detailedShed, { batches: batchesNutrition.batches });


    let newDate = new Date();
    newDate.setDate(newDate.getDate() - 7)

    detailedShed.periods = detailedShed.silos.map((silo) => {
      const newDate = new Date();
      newDate.setDate(newDate.getDate() - 7)
      return {
        id: silo.id,
        select: 'period',
        period: 7,
        start: newDate.toISOString().split('T')[0] + 'T00:00:00-03:00',
        end: new Date().toISOString().split('T')[0] + 'T23:59:59-03:00'
      }
    });

    newDate = new Date();
    newDate.setDate(newDate.getDate() - 50)

    detailedShed.periodsHistory = [
      {
        id: detailedShed.id,
        select: 'day',
        type: 'total',
      }
    ]
    // detailedShed.lastBatch = {...detailedShed.lastBatch, ...detailedShed.batches.find(batch => batch.id === params?.new_batch_id) || detailedShed.batches[0]}

    commit('SET_SELECTED_SHED', detailedShed);
    commit('SET_LAST_BATCH', detailedShed.lastBatch);
  },

  async loadShedDevices({ rootGetters, commit }, shedId) {
    const token = rootGetters['user/getToken'];
    let offset = 0;
    let response;
    let devices = [];

    do {
      response = await getDevices(token.access, shedId, offset);
      devices = devices.concat(response.results);
      offset += 15;
    } while (response.next);

    if (rootGetters['environment/getSelectedShed'] != null) {
      commit('SET_DEVICES_LIST', devices);
    }
  },

  async loadBoxDevices({ rootGetters, commit }, boxes) {
    const token = rootGetters['user/getToken'];
    let offset = 0;
    let response;
    let devices = [];
    let filteredDevices = [];

    do {
      response = await getBoxDevices(token.access, offset);
      devices = devices.concat(response.results);
      offset += 15;
    } while (response.next);

    boxes.forEach((box) => {
      filteredDevices = filteredDevices.concat(devices.filter((d) => d.box == box.id));
    });

    commit('SET_DEVICES_LIST', filteredDevices);
  },

  resetShedsIsLoaded({ commit }) {
    commit('SET_SHEDS_IS_LOADED', false);
  },

  resetPropertiesValues({ commit }) {
    commit('SET_SHEDS_IS_LOADED', false);
    commit('SET_SELECTED_SHED', null);
    commit('SET_SHEDS', []);
    commit('SET_SINGLE_PROPERTY', null);
  },
};
