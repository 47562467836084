<template>
  <li class="side-bar-menu-item external">
    <div
      class="container-menu-item"
      :class="{ 'disabled': disabled, 'expanded': moduleOpened == label, 'selected': selected }">
      <!-- Icon -->
      <FTooltip :theme="tooltipContent.theme" :placement="tooltipContent.placement">
        <div class="container-icon main" @click="toggleAccordion">
          <font-awesome-icon v-if="icon" class="side-bar-icon" :icon="icon" />
          <RoosterIcon v-if="roosterIcon" class="w-5"></RoosterIcon>
        </div>

        <template #popper>
          <span>{{ $t(tooltipContent.content) }}</span>
        </template>
      </FTooltip>

      <!-- Accordion -->
      <div v-if="moduleOpened == label && accordion == true"
        class="container-accordion">
        <div v-for="(menu, i) in accordionMenus" :key="i">
          <router-link v-if="menu.divider == null" :to="menu.route || ''">
            <FTooltip :theme="menu.tooltip.theme" :placement="menu.tooltip.placement">
              <div class="container-icon" :class="[menu.selected ? 'selected' : '', {'disabled': menu.disabled}]">
                <font-awesome-icon :class="getClass(menu, accordionMenus.length)" :icon="getIcon(menu, accordionMenus.length)" />
              </div>
              <template #popper>
                <span>{{ $t(menu.tooltip.content) }}</span>
              </template>
            </FTooltip>
          </router-link>
          <div v-else class="divider"></div>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import RoosterIcon from '@/assets/icons/RoosterIcon.vue';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'ExternalMenuItem',
  components: {
    RoosterIcon,
  },
  props: {
    showTooltip: { type: Boolean, default: false },
    tooltipContent: { type: Object },
    disabled: { type: Boolean, default: false },
    accordion: { type: Boolean, default: false },
    selected: { type: Boolean, default: false },
    icon: { type: String, default: null },
    roosterIcon: { type: Boolean, default: false },
    accordionMenus: { type: Array },
    label: { type: String, required: true },
    hasInternalContent: { type: Boolean, default: false },
    isMatrix: { type: Boolean, default: false },
    role: {type: String, default: null}
  },
  data() {
    return {
      expandedAccordion: false,
    };
  },
  computed: {
    ...mapGetters('interface', ['getModuleOpened']),
    ...mapGetters('user', ['getBroilerRoles', 'getMatrixRoles']),
    moduleOpened: {
      get() {
        return this.getModuleOpened;
      },
      set(value) {
        this.SET_MODULE_OPENED(value);
      },
    },
  },
  methods: {
    ...mapMutations('interface', ['SET_MODULE_OPENED']),
    toggleAccordion() {
      if (this.accordion) {
        this.expandedAccordion = !this.expandedAccordion;
      }

      if (this.hasInternalContent) {
        if (this.moduleOpened == null) {
          this.moduleOpened = this.label;
        } else if (this.moduleOpened == this.label) {
          this.moduleOpened = null;
        } else {
          this.moduleOpened = this.label;
        }
      }
    },
    getIcon(icon, accordion_size){
      if (accordion_size == 6) {
        if(this.getBroilerRoles != null) {
          if (this.getBroilerRoles.includes(icon.role)) {
            return icon.icon
          } else return icon.disabledIcon
        } else return icon.disabledIcon
      } else if (accordion_size == 5) {
        if(this.getMatrixRoles != null) {
          if (this.getMatrixRoles.includes(icon.role)) {
            return icon.icon
          } else return icon.disabledIcon
        } else return icon.disabledIcon
      }
    },
    getClass(icon, accordion_size){
      if (accordion_size == 6) {
        if(this.getBroilerRoles != null) {
          if (this.getBroilerRoles.includes(icon.role)) {
            return ""
          } else return "my-2 transition cursor-not-allowed text-gray-500"
        } else return "my-2 transition cursor-not-allowed text-gray-500"
      } else if (accordion_size == 5) {
        if(this.getMatrixRoles != null) {
          if (this.getMatrixRoles.includes(icon.role)) {
            return ""
          } else return "my-2 transition cursor-not-allowed text-gray-500"
        } else return "my-2 transition cursor-not-allowed text-gray-500"
      }
    },
  },
};
</script>
