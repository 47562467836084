export const getters = {
  getToken(state) {
    return state.token;
  },

  getIsGuest(state) {
    return state.isGuest;
  },

  getUserName(state) {
    return state.name;
  },

  getBroilerRoles(state) {
    return state.roles["frangos_de_corte"];
  },

  getMatrixRoles(state) {
    return state.roles["matrizeiro"];
  },
};
