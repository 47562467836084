export const getters = {
  getFilters(state) {
    return state.filters;
  },

  getSearchOptions(state) {
    return state.searchOptions;
  },

  getSearchPcpOptions(state) {
    return state.searchPcpOptions;
  },

  getIsMatrix(state) {
    return state.isMatrix;
  },

  getAlertFilters(state) {
    return state.alertFilters;
  },

  getIsHatch(state) {
    return state.isHatch;
  },

  getMatrixFilters(state) {
    return state.matrixFilters;
  },

  getSlaughterPredictionFilters(state) {
    return state.slaughterPredictionFilters;
  },

  getSanityFilters(state) {
    return state.sanityFilters;
  },

  getPcpFilters(state) {
    return state.pcpFilters;
  },

  getPcpPlanningFilters(state) {
    return state.pcpPlanningFilters;
  },

  getNutritionFilters(state) {
    return state.nutritionFilters;
  },

  getConfiguredFilters(state) {
    return state.configuredFilters;
  },

  getPcpConfiguredFilters(state) {
    return state.pcpConfiguredFilters;
  },

  getPcpPlanningConfiguredFilters(state) {
    return state.configuredFilters;
  },

  getAlertsHistoryFilters(state) {
    return state.alertsHistoryFilters;
  },

  getIndexFilter(state) {
    return state.indexFilter;
  },

  getPcpIndexFilter(state) {
    return state.pcpIndexFilter;
  },

  getShowDisabledProperties(state) {
    return state.showDisabledProperties;
  },

  getShowSlaughterState(state) {
    return state.showSlaughterState;
  },

  getShowLodgeState(state) {
    return state.showLodgeState;
  },

  getShowPreLodgeState(state) {
    return state.showPreLodgeState;
  },

  getShowPriorityState(state) {
    return state.showPriorityState;
  },

  getRequestParams(state) {
    return state.requestParams;
  },

  getFlagSlaughterPrediction(state) {
    return state.flagSlaughterPrediction;
  },

  getSearchSlaughterPrediction(state) {
    return state.searchSlaughterPrediction;
  },

  getFlagPcpPrediction(state) {
    return state.flagPcpPrediction;
  },

  getFlagPcpSimulation(state) {
    return state.flagPcpSimulation;
  },

  getSearchPcpPrediction(state) {
    return state.searchPcpPrediction;
  },
};
